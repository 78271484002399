/** Allows popover interfaces to be scrolled **/
.stacks-popover .popover-content .popover-viewport {
  overflow: scroll;
}

/*
This prevents a bug in Android that will result in no popovers being able to be opened.
See #429 for more information
*/
.popover-viewport {
  position: relative;
  border: none;
  width: 100%;
}
